import './travel-inspiration.scss';

import Swiper from 'swiper';
import { A11y, Pagination, Keyboard, Navigation } from 'swiper/modules';

import 'swiper/scss';
import 'swiper/scss/thumbs';

class TravelInspoSlider {
    constructor (element) {
        this.$slider = element.querySelector('[data-travel-inspiration-slider="slider"]');
        this.$pagination = element.querySelector('[data-swiper-pagination]');
        this.$prevBtn = element.querySelector('[data-swiper-prev]');
        this.$nextBtn = element.querySelector('[data-swiper-next]');
        this.$skipBtn = element.querySelector('[data-swiper-skip]');
        this.$$submitBtn = element.querySelectorAll('[data-travel-inspiration-submit]');
        this.$selectTopics = element.querySelector('[data-travel-inspiration-topics="select"]');
        this.$$selectTopicsOptions = element.querySelectorAll('[data-travel-inspiration-topics="checkbox"]');
        this.$selectShip = element.querySelector('[data-travel-inspiration-ship="select"]');
        this.$$selectShipOptions = element.querySelectorAll('[data-travel-inspiration-ship="checkbox"]');
        this.$selectTravelPeriode = element.querySelector('[data-travel-inspiration-travel-periode="select"]');
        this.$selectTravelPeriode6Months = element.querySelector('[data-travel-inspiration-travel-periode="plus-6-months"]');
        this.$selectTravelPeriodeResetAll = element.querySelector('[data-travel-inspiration-travel-periode="reset-all"]');
        this.slider = null;
    }

    init () {
        this.initSlider();
        this.setTopics();
        this.setShip();
        this.setTravelPeriode();

        this.$skipBtn.onclick = (e) => {
            e.preventDefault();
            this.$nextBtn.click();
        };
    }

    initSlider () {
        const paginationLabel = this.$pagination.dataset.paginationLabel;

        this.slider = new Swiper(this.$slider, {
            modules: [A11y, Pagination, Keyboard, Navigation],
            slidesPerView: 'auto',
            autoHeight: true,
            lazyPreloadPrevNext: 1,
            simulateTouch: false,
            touchMoveStopPropagation: false,
            keyboard: {
                enabled: true,
                onlyInViewport: false,
                pageUpDown: true
            },
            pagination: {
                el: this.$pagination,
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"><span class="travel-inspiration__pagination-label">' + paginationLabel + ' ' + (index + 1) + '</span></span>';
                }
            },
            navigation: {
                prevEl: this.$prevBtn,
                nextEl: this.$nextBtn
            }
        });

        this.slider.on('slideChange', () => {
            const bullets = this.$pagination.querySelectorAll('.swiper-pagination-bullet');

            if (this.slider.activeIndex >= 3) {
                this.$pagination.parentElement.classList.add('travel-inspiration__pagination--offset');
            } else {
                this.$pagination.parentElement.classList.remove('travel-inspiration__pagination--offset');
            }

            bullets.forEach((bullet, index) => {
                if (index < this.slider.activeIndex) {
                    bullet.classList.add('swiper-pagination-bullet-prev');
                } else {
                    bullet.classList.remove('swiper-pagination-bullet-prev');
                }
            });

            if (this.slider.isEnd) {
                this.$$submitBtn.forEach($submitBtn => {
                    $submitBtn.classList.remove('is--hidden');
                    this.$nextBtn.classList.add('is--hidden');
                    this.$skipBtn.classList.add('is--hidden');
                });
            } else {
                this.$$submitBtn.forEach($submitBtn => {
                    $submitBtn.classList.add('is--hidden');
                    this.$nextBtn.classList.remove('is--hidden');
                    this.$skipBtn.classList.remove('is--hidden');
                });
                this.$slider.classList.remove('travel-inspiration__final');
            }
        });

        this.slider.on('slideChangeTransitionEnd', () => {
            if (this.slider.isEnd) {
                this.$slider.classList.add('travel-inspiration__final');
            }
        });
    }

    setTopics () {
        this.$$selectTopicsOptions.forEach($selectTopicsOption => {
            $selectTopicsOption.onchange = () => {
                const optionValue = $selectTopicsOption.dataset.optionKey;
                const selectTopicOption = this.$selectTopics.querySelector(`option[value="${optionValue}"]`);

                selectTopicOption.selected = $selectTopicsOption.checked;
            };
        });
    }

    setShip () {
        this.$$selectShipOptions.forEach($selectShipOption => {
            $selectShipOption.onchange = () => {
                const optionValue = $selectShipOption.dataset.optionKey;
                const $$selectShipOptions = this.$selectShip.querySelectorAll('option');

                $$selectShipOptions.forEach($shipOption => {
                    const matches = $shipOption.innerText.includes(optionValue);

                    if (matches) {
                        $shipOption.selected = $selectShipOption.checked;
                    }
                });
            };
        });
    }

    setTravelPeriode () {
        const $travelPeriodeStartDate = this.$selectTravelPeriode.querySelector('[data-travel-inspiration-travel-perido-start]');
        const $travelPeriodeEndDate = this.$selectTravelPeriode.querySelector('[data-travel-inspiration-travel-perido-end]');
        const $datepickerRoot = this.$selectTravelPeriode.querySelector('[data-datepicker="root"]');
        const $datepickerStart = this.$selectTravelPeriode.querySelector('[data-travel-inspiration-datepicker-start]');
        const $datepickerEnd = this.$selectTravelPeriode.querySelector('[data-travel-inspiration-datepicker-end]');
        const $datepickerInput = this.$selectTravelPeriode.querySelector('[data-datepicker="datepicker-input"]');

        if (this.$selectTravelPeriode6Months) {
            this.$selectTravelPeriode6Months.onchange = () => {
                if (this.$selectTravelPeriode6Months.checked) {
                    this.$selectTravelPeriodeResetAll.checked = false;
                    const currentDate = new Date();
                    const endDate = new Date();
                    $travelPeriodeStartDate.value = formatDate(currentDate);
                    endDate.setMonth(endDate.getMonth() + 6);
                    $travelPeriodeEndDate.value = formatDate(endDate);
                    $travelPeriodeStartDate.setAttribute('name', $travelPeriodeStartDate.dataset.optionName);
                    $travelPeriodeEndDate.setAttribute('name', $travelPeriodeEndDate.dataset.optionName);
                    $datepickerStart.value = '';
                    $datepickerEnd.value = '';
                    $datepickerInput.value = $datepickerInput.dataset.datepickerPlaceholder;
                    $datepickerRoot.classList.remove('is--active');
                } else {
                    $travelPeriodeStartDate.value = '';
                    $travelPeriodeEndDate.value = '';
                    $travelPeriodeStartDate.setAttribute('name', '');
                    $travelPeriodeEndDate.setAttribute('name', '');
                }
            };
        }

        if (this.$selectTravelPeriodeResetAll) {
            this.$selectTravelPeriodeResetAll.onchange = () => {
                if (this.$selectTravelPeriodeResetAll.checked) {
                    this.$selectTravelPeriode6Months.checked = false;
                    $travelPeriodeStartDate.value = '';
                    $travelPeriodeEndDate.value = '';
                    $travelPeriodeStartDate.setAttribute('name', '');
                    $travelPeriodeEndDate.setAttribute('name', '');
                    $datepickerStart.value = '';
                    $datepickerEnd.value = '';
                    $datepickerInput.value = $datepickerInput.dataset.datepickerPlaceholder;
                    $datepickerRoot.classList.remove('is--active');
                }
            };
        }

        document.onclick = (event) => {
            if ($datepickerRoot.contains(event.target)) {
                this.$selectTravelPeriode6Months.checked = false;
                this.$selectTravelPeriodeResetAll.checked = false;
                $travelPeriodeStartDate.value = '';
                $travelPeriodeEndDate.value = '';
                $travelPeriodeStartDate.setAttribute('name', '');
                $travelPeriodeEndDate.setAttribute('name', '');
            }
        };

        function formatDate (date) {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            const year = date.getFullYear();

            day = (day < 10) ? '0' + day : day;
            month = (month < 10) ? '0' + month : month;

            return `${day}-${month}-${year}`;
        }
    }
}

export { TravelInspoSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$TravelInspoSlider = $context.querySelectorAll('[data-travel-inspiration-slider="root"]');
        for (let i = 0; i < $$TravelInspoSlider.length; i++) {
            const $slider = new TravelInspoSlider($$TravelInspoSlider[i]);
            $slider.init($$TravelInspoSlider[i]);
        }
    }
});
